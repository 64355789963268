/*
 * 404.js
 * ------
 * Exports the 404 page component.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import SiteMetadata from "../components/site-metadata";
import TextPageLayout from "../components/text-page-layout";

const metadata = {
  title: "404 Pagina Non Trovata",
};

const PageNotFound = ({ location }) => (
  <TextPageLayout>
    <SiteMetadata
      location={location}
      {...metadata}
    />

    <h1>{metadata.title}</h1>
    <p>
      La pagina che stai cercando non esiste.
    </p>
    <p>
      Ritorna alla
      {" "}
      <Link to="/">pagina Home</Link>
      .
    </p>
  </TextPageLayout>
);

PageNotFound.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PageNotFound;
